@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
  font-family: "Inter", sans-serif;
}
.custom-width {
  width: 66vw; /* Tăng chiều rộng lên 66% chiều rộng của màn hình */
}